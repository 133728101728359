import React from "react";
import { Button } from "react-blueprint-library";
import { FORM_ID } from "../../../../constants/Forms";
import ReviewPersonalInfo from "./ReviewSignForm1/ReviewPersonalInfo";
import "../../../../assets/stylesheets/ReviewAndSign.scss";
import ReviewSignHeader from "./ReviewSignForm1/ReviewSignHeader";
import ReviewResAddress from "./ReviewSignForm1/ReviewResAddress";
import ReviewBusInfo from "./ReviewSignForm1/ReviewBusInfo";
import ReviewBrokerDealer from "./ReviewSignForm1/ReviewBrokerDealer";
import ReviewCCB from "./ReviewSignForm1/ReviewCCB";
import ReviewEOC from "./ReviewSignForm1/ReviewEOC";
import ReviewDirectDeposit from "./ReviewSignForm1/ReviewDirectDeposit";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../../constants/Contract.js";
import NavigationButtons from "../../../SharedComponents/NavigationButtons";
import { isCareerContract } from "../../../../common/Functions.js";
import { useDispatch } from "react-redux";
import { ccbActions } from "../../../../store/actions";

const ReviewSignForm1 = (props) => {
  const dispatch = useDispatch();

  const editToggle = (event) => {
    dispatch(ccbActions.setIsBack(false));
    switch (event.target.id) {
      case "pInfoEdit":
        props.toggleForm(FORM_ID.PERSONAL_INFO);
        break;
      case "resHistEdit":
        props.toggleForm(FORM_ID.RESIDENTIAL_HISTORY);
        break;
      case "bInfoEdit":
        props.toggleForm(FORM_ID.BUSINESS_INFO);
        break;
      case "eduEdit":
        props.toggleForm(FORM_ID.EDUCATION);
        break;
      case "empHistEdit":
        props.toggleForm(FORM_ID.EMPLOYMENT_HISTORY);
        break;
      case "psrEdit":
        props.toggleForm(FORM_ID.PERSONAL_SALES_RECORD);
        break;
      case "criminalEdit":
        props.toggleForm(FORM_ID.CCB_INTRO);
        break;
      case "regEdit":
        props.toggleForm(FORM_ID.REGULATORY_ACTION_DISCLOSURE);
        break;
      case "civEdit":
        props.toggleForm(FORM_ID.CIVIL_JUDICIAL_DISCLOSURE);
        break;
      case "customerEdit":
        props.toggleForm(FORM_ID.CUSTOMER_COMPLAINT_DISCLOSURE);
        break;
      case "termEdit":
        props.toggleForm(FORM_ID.TERMINATION_DISCLOSURE);
        break;
      case "financeEdit":
        props.toggleForm(FORM_ID.FINANCIAL_DISCLOSURE);
        break;
      case "ddEdit":
        props.toggleForm(FORM_ID.DIRECT_DEPOSIT);
        break;
      case "corpInfoEdit":
        props.toggleForm(FORM_ID.CORPORATE_INFO);
        break;
      case "memberCorpInfoEdit":
        props.toggleForm(FORM_ID.MEMBER_CORPORATE_INFO);
        break;
      case "corpCCBInfoEdit":
        props.toggleForm(FORM_ID.CORPORATE_CCB);
        break;
      case "CorpBInfoEdit":
        props.toggleForm(FORM_ID.CORPORATE_BUSINESS_ADDRESS);
        break;
      case "corpEOInfoEdit":
        props.toggleForm(FORM_ID.CORPORATE_ERRORS_OMISSIONS);
        break;
      case "corpDDEdit":
        props.toggleForm(FORM_ID.CORPORATE_DIRECT_DEPOSIT);
        break;
      case "resAddrEdit":
        props.toggleForm(FORM_ID.RESIDENTIAL_ADDRESS);
        break;
      case "brokeDealEdit":
        props.toggleForm(FORM_ID.BROKER_DEALER);
        break;
      case "ccbEdit":
        props.toggleForm(FORM_ID.CCB);
        break;
      case "eoEdit":
        props.toggleForm(FORM_ID.ERRORS_OMISSIONS);
        break;
      case "mmlisEdit":
        props.toggleForm(FORM_ID.MMLIS_INTRO);
        break;

      default:
        return;
    }
  };

  const handleShowBackModal = () => {
    if (
      props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
      props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
    ) {
      props.toggleForm(FORM_ID.ERRORS_OMISSIONS);
    } else if (
      props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS ||
      props.isMMLIS
    ) {
      props.toggleForm(FORM_ID.MMLIS_OBA_CRIA);
    } else if (
      props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
    ) {
      dispatch(ccbActions.setIsBack(true));
      props.toggleForm(FORM_ID.FINANCIAL_DISCLOSURE);
    } else {
      props.toggleForm(FORM_ID.DIRECT_DEPOSIT);
    }
  };

  const handleNext = () => {
    dispatch(ccbActions.setIsBack(false));
    props.toggleForm(FORM_ID.REVIEW_SIGN_2);
  };
  return (
    <div>
      {props.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER && (
        <>
          <div id="reviewsignform1">
            <ReviewSignHeader
              step="1"
              title="Review Application"
            ></ReviewSignHeader>

            <ReviewPersonalInfo
              personalInformation={props.personalInformationData}
              toggleForm={props.toggleForm}
              candidatePresentationMode={props.candidatePresentationMode}
            ></ReviewPersonalInfo>
            <hr />
            <ReviewResAddress
              residentialAddress={props.resAddressData}
              toggleForm={props.toggleForm}
            ></ReviewResAddress>
            <hr />
            <ReviewBusInfo
              businessAddress={props.busInfoData}
              toggleForm={props.toggleForm}
            ></ReviewBusInfo>
            <hr />
            <ReviewBrokerDealer
              brokerDealerInformation={props.brokerDealerData}
              toggleForm={props.toggleForm}
            ></ReviewBrokerDealer>
            <hr />
            <ReviewCCB
              characterCreditBonding={props.ccbData}
              toggleForm={props.toggleForm}
            ></ReviewCCB>
            <hr />
            <ReviewEOC
              errorsOmissionsCoverage={props.eocData}
              toggleForm={props.toggleForm}
            ></ReviewEOC>
            <hr />
            <ReviewDirectDeposit
              directDepositInformation={props.ddInfoData}
              toggleForm={props.toggleForm}
            ></ReviewDirectDeposit>

            <div className="row actions">
              <div className="col-12">
                <span
                  id="backbtn"
                  onClick={() => {
                    props.toggleForm(FORM_ID.DIRECT_DEPOSIT);
                  }}
                >
                  <strong id="backbtn-txt"> Back </strong>
                </span>
                <Button
                  id="nextbtn"
                  onClick={() => {
                    props.toggleForm(FORM_ID.REVIEW_SIGN_2);
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      {isCareerContract(props.contracttypedesc) && (
        <>
          <>
            <ReviewSignHeader
              step="1"
              title="Review Application"
            ></ReviewSignHeader>
            <div id="reviewsignformCAS">
              <div id="message-box" className="row">
                <div className="col-md-12" id="reviewCompletedText">
                  <h1 id="headerText">
                    Here is your chance to review and edit your application
                    before submitting it.
                  </h1>
                  <div id="reviewCheckBox">
                    <span
                      id="reviewCheck"
                      className="icon-check-circle icon-lg"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12" id="reviewCompletedText">
                  <h3>You've completed all of the following sections.</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-5" id="pInfoReview">
                  <span>Personal Information</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="pInfoEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="resHistoryReview">
                  <span>Residential History</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="resHistEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              {props.contracttypedesc !==
                CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN && (
                <div className="row">
                  <div className="col-5" id="bInfoReview">
                    <span>Business Information</span>
                  </div>
                  <span id="review-edit-btn" onClick={editToggle}>
                    <strong id="bInfoEdit">Review/Edit </strong>
                    <span className="icon-xs icon-caret-spin-right"></span>
                  </span>
                </div>
              )}

              <div className="row">
                <div className="col-5" id="eduReview">
                  <span>Education</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="eduEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div id="eyebrow-text">
                <strong className="eyebrow">Employment History</strong>
              </div>

              <div className="row">
                <div className="col-5" id="empHistReview">
                  <span>Employment History</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="empHistEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>
              {props.psrData.length > 0 && (
                <div className="row">
                  <div className="col-5" id="psrReview">
                    {props.hasNYRegs === false ? (
                      <span>Personal Sales Record</span>
                    ) : (
                      <span>Personal Production Record</span>
                    )}
                  </div>
                  <span id="review-edit-btn" onClick={editToggle}>
                    <strong id="psrEdit">Review/Edit </strong>
                    <span className="icon-xs icon-caret-spin-right"></span>
                  </span>
                </div>
              )}
              <div id="eyebrow-text">
                <strong className="eyebrow">
                  Character, Credit, And Bonding
                </strong>
              </div>

              <div className="row">
                <div className="col-5" id="criminalReview">
                  <span>Criminal Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="criminalEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="regReview">
                  <span>Regulatory Action Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="regEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="civReview">
                  <span>Civil Judicial Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="civEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="customerReivew">
                  <span>Customer Complaint Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="customerEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="termReview">
                  <span>Termination Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="termEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="financeReview">
                  <span>Financial Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="financeEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div id="eyebrow-text"></div>

              {props.contracttypedesc !==
                CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY && (
                <div className="row">
                  <div className="col-5" id="ddReview">
                    <span>Direct Deposit Information</span>
                  </div>
                  <span id="review-edit-btn" onClick={editToggle}>
                    <strong id="ddEdit">Review/Edit </strong>
                    <span className="icon-xs icon-caret-spin-right"></span>
                  </span>
                </div>
              )}

              {props.isMMLIS && (
                <div className="row">
                  <div className="col-5" id="mmlisReview">
                    <span>MMLIS</span>
                  </div>
                  <span id="review-edit-btn" onClick={editToggle}>
                    <strong id="mmlisEdit">Review/Edit </strong>
                    <span className="icon-xs icon-caret-spin-right"></span>
                  </span>
                </div>
              )}

              <NavigationButtons
                onBack={handleShowBackModal}
                onNext={handleNext}
                nxtbtnid={"nextbtn"}
                bckbtnid={"backbtn"}
                isLoading={false}
              />
            </div>
          </>
        </>
      )}

      {(props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
        props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
        <>
          <>
            <ReviewSignHeader
              step="1"
              title="Review Application"
            ></ReviewSignHeader>
            <div id="reviewsignformCAS">
              <div id="message-box" className="row">
                <div className="col-md-12" id="reviewCompletedText">
                  <h1 id="headerText">
                    Here is your chance to review and edit your application
                    before submitting it.
                  </h1>
                  <div id="reviewCheckBox">
                    <span
                      id="reviewCheck"
                      className="icon-check-circle icon-lg"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12" id="reviewCompletedText">
                  <h3>You've completed all of the following sections.</h3>
                </div>
              </div>

              {props.contracttypedesc ===
                CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div id="eyebrow-text">
                        <strong className="eyebrow">Corporation</strong>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6" id="pInfoReview">
                      <span>Corporation Information</span>
                    </div>
                    <span id="review-edit-btn" onClick={editToggle}>
                      <strong id="corpInfoEdit">Review/Edit </strong>
                      <span className="icon-xs icon-caret-spin-right"></span>
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-6" id="bInfoReview">
                      <span>Business Information</span>
                    </div>
                    <span id="review-edit-btn" onClick={editToggle}>
                      <strong id="CorpBInfoEdit">Review/Edit </strong>
                      <span className="icon-xs icon-caret-spin-right"></span>
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-6" id="pInfoReview">
                      <span>Corporation Character, Credit, and Bonding</span>
                    </div>
                    <span id="review-edit-btn" onClick={editToggle}>
                      <strong id="corpCCBInfoEdit">Review/Edit </strong>
                      <span className="icon-xs icon-caret-spin-right"></span>
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-6" id="pInfoReview">
                      <span>
                        Corporation Errors & Omissions (E&O) Coverage, Group
                        Insurance
                      </span>
                    </div>
                    <span id="review-edit-btn" onClick={editToggle}>
                      <strong id="corpEOInfoEdit">Review/Edit </strong>
                      <span className="icon-xs icon-caret-spin-right"></span>
                    </span>
                  </div>

                  <div className="row">
                    <div className="col-6" id="ddReview">
                      <span>Direct Deposit Information</span>
                    </div>
                    <span id="review-edit-btn" onClick={editToggle}>
                      <strong id="corpDDEdit">Review/Edit </strong>
                      <span className="icon-xs icon-caret-spin-right"></span>
                    </span>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-md-12">
                  <div id="eyebrow-text">
                    <strong className="eyebrow">Member of Corporation</strong>
                  </div>
                </div>
              </div>
              {props.contracttypedesc ===
                CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP && (
                <>
                  <div className="row">
                    <div className="col-6" id="pInfoReview">
                      <span>Corporation Information</span>
                    </div>
                    <span id="review-edit-btn" onClick={editToggle}>
                      <strong id="memberCorpInfoEdit">Review/Edit </strong>
                      <span className="icon-xs icon-caret-spin-right"></span>
                    </span>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-6" id="pInfoReview">
                  <span>Personal Information</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="pInfoEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-6" id="resHistoryReview">
                  <span>Residential Address</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="resAddrEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-6" id="bInfoReview">
                  <span>Business Information</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="bInfoEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-6" id="resHistoryReview">
                  <span>Broker Dealer Information</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="brokeDealEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-6" id="resHistoryReview">
                  <span>Character, Credit, and Bonding</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="ccbEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-6" id="resHistoryReview">
                  <span>
                    Errors & Omissions (E&O) Coverage, Group Insurance
                  </span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="eoEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <NavigationButtons
                onBack={handleShowBackModal}
                onNext={handleNext}
                nxtbtnid={"nextbtn"}
                bckbtnid={"backbtn"}
                isLoading={false}
              />
            </div>
          </>
        </>
      )}

      {props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS && (
        <>
          <>
            <ReviewSignHeader
              step="1"
              title="Review Application"
            ></ReviewSignHeader>
            <div id="reviewsignformCAS">
              <div id="message-box" className="row">
                <div className="col-md-12" id="reviewCompletedText">
                  <h1 id="headerText">
                    Here is your chance to review and edit your application
                    before submitting it.
                  </h1>
                  <div id="reviewCheckBox">
                    <span
                      id="reviewCheck"
                      className="icon-check-circle icon-lg"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12" id="reviewCompletedText">
                  <h3>You've completed all of the following sections.</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-5" id="pInfoReview">
                  <span>Personal Information</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="pInfoEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="resHistoryReview">
                  <span>Residential History</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="resHistEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              {props.contracttypedesc !==
                CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN && (
                <div className="row">
                  <div className="col-5" id="bInfoReview">
                    <span>Business Information</span>
                  </div>
                  <span id="review-edit-btn" onClick={editToggle}>
                    <strong id="bInfoEdit">Review/Edit </strong>
                    <span className="icon-xs icon-caret-spin-right"></span>
                  </span>
                </div>
              )}

              <div id="eyebrow-text">
                <strong className="eyebrow">Employment History</strong>
              </div>

              <div className="row">
                <div className="col-5" id="empHistReview">
                  <span>Employment History</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="empHistEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div id="eyebrow-text">
                <strong className="eyebrow">
                  Character, Credit, And Bonding
                </strong>
              </div>

              <div className="row">
                <div className="col-5" id="criminalReview">
                  <span>Criminal Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="criminalEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="regReview">
                  <span>Regulatory Action Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="regEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="civReview">
                  <span>Civil Judicial Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="civEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="customerReivew">
                  <span>Customer Complaint Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="customerEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="termReview">
                  <span>Termination Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="termEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div className="row">
                <div className="col-5" id="financeReview">
                  <span>Financial Disclosure</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="financeEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>

              <div id="eyebrow-text"></div>

              <div className="row">
                <div className="col-5" id="mmlisReview">
                  <span>MMLIS</span>
                </div>
                <span id="review-edit-btn" onClick={editToggle}>
                  <strong id="mmlisEdit">Review/Edit </strong>
                  <span className="icon-xs icon-caret-spin-right"></span>
                </span>
              </div>
              <NavigationButtons
                onBack={handleShowBackModal}
                onNext={handleNext}
                nxtbtnid={"nextbtn"}
                bckbtnid={"backbtn"}
                isLoading={false}
              />
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default ReviewSignForm1;
