import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FORM_ID } from "../../../../../constants/Forms";
import {
  CCB_FORM_ID,
  bankruptcyStepHeaderText,
  bondStepHeaderText,
  headerText,
  judgmentLienStepHeaderText,
  subHeaderText,
} from "./constants";
import { isDrpSummaryPage } from "./utils";

import DisclosureReportingPageLayout from "../common/DRPLayout";
import BankruptcyDisclosureActionDetails from "./BankruptcyDisclosureForm/AddDRPForms/BankruptcyDisclosureActionDetails";
import BankruptcyDisclosureComments from "./BankruptcyDisclosureForm/AddDRPForms/BankruptcyDisclosureComments";
import BankruptcyDisclosureCompromiseDetails from "./BankruptcyDisclosureForm/AddDRPForms/BankruptcyDisclosureCompromiseDetails";
import BankruptcyDisclosureCourtDetails from "./BankruptcyDisclosureForm/AddDRPForms/BankruptcyDisclosureCourtDetails";
import BankruptcyDisclosureDispositionDetails from "./BankruptcyDisclosureForm/AddDRPForms/BankruptcyDisclosureDispositionDetails";
import BankruptcyDisclosureOrgDetails from "./BankruptcyDisclosureForm/AddDRPForms/BankruptcyDisclosureOrgDetails";
import BankruptcyDisclosureSIPADetails from "./BankruptcyDisclosureForm/AddDRPForms/BankruptcyDisclosureSIPADetails";
import FinancialDisclosureDRPSummary from "./FinancialDisclosureDRPSummary";
import FinancialDisclosureForm from "./FinancialDisclosureForm";

import { useOktaAuth } from "@okta/okta-react";
import {
  deleteBankruptcyDRP,
  getBankruptcyDRP,
  insertBankruptcyDRP,
  updateBankruptcyDRP,
} from "../../../../../api/CCB/DRP/bankruptcyDisclosureDRP";
import {
  deleteBondDRP,
  getBondDRP,
  insertBondDRP,
  updateBondDRP,
} from "../../../../../api/CCB/DRP/bondDisclosureDRP";
import {
  getJudgmentLienDRP,
  insertJudgmentLienDRP,
  updateJudgmentLienDRP,
} from "../../../../../api/CCB/DRP/judgmentLienDisclosureDRP";
import { UtagTracking } from "../../../../../utils/utag-tracking";
import AddDRPFormLayout from "../common/AddDRPFormLayout";
import BondAddDRPComments from "./BondDisclosureForm/AddDRPForms/BondDisclosureAddDRPCommentsForm";
import BondAddDRPDispositionDetails from "./BondDisclosureForm/AddDRPForms/BondDisclosureAddDRPDispositionDetailsForm";
import BondAddDRPOrganizationInformationForm from "./BondDisclosureForm/AddDRPForms/BondDisclosureAddDRPOrganizationInformationForm";
import JudgmentLienAddDRPCommentsForm from "./JudgmentLienDisclosureForm/AddDRPForms/JudgmentLienAddDRPCommentsForm";
import JudgmentLienAddDRPCourtDetailsForm from "./JudgmentLienDisclosureForm/AddDRPForms/JudgmentLienAddDRPCourtDetailsForm";
import JudgmentLienAddDRPDispositionDetailsForm from "./JudgmentLienDisclosureForm/AddDRPForms/JudgmentLienAddDRPDispositionDetailsForm";
import JudgmentLienAddDRPJudgmentLienDetailsForm from "./JudgmentLienDisclosureForm/AddDRPForms/JudgmentLienAddDRPJudgmentLienDetailsForm";

import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../../../constants/Contract";

const FinancialDisclosure = ({
  toggleForm,
  subId: oktaSubId,
  updateCCB14CompletedInd,
  updateCCB14Step,
  candidateId,
  agencynumber,
  contracttypeid,
  contractType,
}) => {
  const { data, isBack } = useSelector((state) => state.ccb14);
  const [tempBondDRP, setTempBondDRP] = useState({});
  const [tempBankruptcyDRP, setTempBankruptcyDRP] = useState({});
  const [tempJudgmentLienDRP, setTempJudgmentLienDRP] = useState({});
  const [bondDRPs, setBondDRPs] = useState([]);
  const [bankruptcyDRPs, setBankruptcyDRPs] = useState([]);
  const [judgmentLienDRPs, setJudgmentLienDRPs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isOrg, setIsOrg] = useState(false);

  const [isDRPCompleted, setIsDRPCompleted] = useState(false);
  const [isDRPNextDisabled, setIsDRPNextDisabled] = useState(true);

  const {
    authState: {
      idToken: {
        claims: { sub },
      },
    },
  } = useOktaAuth();

  const {
    persBankruptcy,
    orgBankruptcy,
    bondDiscl,
    judgLienDisclosures,
    unchecked,
  } = isDrpSummaryPage(data);

  useEffect(() => {
    if (
      (persBankruptcy?.length > 0 ||
        orgBankruptcy?.length > 0 ||
        bondDiscl?.length > 0 ||
        judgLienDisclosures?.length > 0) &&
      isBack
    ) {
      setCurrentFormId(CCB_FORM_ID.DRP_SUMMARY);
    }
    setData();
    UtagTracking.sendPageNameView(
      "financialdisclosureform",
      candidateId,
      agencynumber,
      contracttypeid
    );

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [data]);

  const setData = async () => {
    setLoading(true);
    const DRPData = await Promise.all([
      getBondDRP(sub),
      getJudgmentLienDRP(sub),
      getBankruptcyDRP(sub),
    ]);
    const { data: bondDRPData } = DRPData[0];
    const { data: judgmentLienData } = DRPData[1];
    const { bankruptcyData } = DRPData[2];

    if (bondDRPData !== "") {
      setBondDRPs(bondDRPData);
    }
    if (judgmentLienData !== "") {
      setJudgmentLienDRPs(judgmentLienData);
    }
    if (bankruptcyData !== "") {
      setBankruptcyDRPs(bankruptcyData);
    }

    const disableNext =
      getPersBankruptcyDRPList(bankruptcyData)?.length <
        persBankruptcy?.length ||
      getOrgBankruptcyDRPList(bankruptcyData)?.length < orgBankruptcy?.length ||
      bondDRPData?.length < bondDiscl?.length ||
      judgmentLienData?.length < judgLienDisclosures?.length;

    setIsDRPNextDisabled(disableNext);
    setLoading(false);
  };
  const [currentFormId, setCurrentFormId] = useState(
    CCB_FORM_ID.FINANCIAL_DISCL
  );

  useEffect(() => {
    if (currentFormId === CCB_FORM_ID.DRP_SUMMARY) {
      setIsOrg(false);
      setTempBankruptcyDRP({});
      setData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormId]);

  const updateCCBForm = (formId, options) => {
    window.scrollTo(0, 0);
    if (formId === CCB_FORM_ID.DRP_SUMMARY) {
      setEditIndex(null);
      setTempBondDRP({});
      setTempJudgmentLienDRP({});
    }

    if (!isDRPCompleted && (isBack || options?.isBackClick)) {
      updateCCB14Step({ finDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ finDisclosureCompleteInd: false });
    }

    setCurrentFormId(formId);
  };
  const updateTempBondDRP = (newTempDRP) => {
    setTempBondDRP(newTempDRP);
  };
  const updateTempBankruptcyDRP = (newTempDRP) => {
    setTempBankruptcyDRP(newTempDRP);
  };
  const updateTempJudgmentLienDRP = (newTempDRP) => {
    setTempJudgmentLienDRP(newTempDRP);
  };
  const hasCompromise = (tmpDRP) => {
    return tmpDRP.actionType === "Compromise";
  };
  const hasSIPA = (tmpDRP) => {
    return tmpDRP.dispositionType === "SIPA Trustee Appointed";
  };

  const updateBankruptcyDisclosureDRPs = async (newDRP) => {
    if (editIndex !== null) {
      let newBankruptcyDRPs = bankruptcyDRPs.filter(
        (e) => e.ccbdrpid !== editIndex
      );
      newBankruptcyDRPs.push({
        bankruptcydrp: newDRP,
        ccbdrpid: editIndex,
      });
      setBankruptcyDRPs(newBankruptcyDRPs);
      await updateBankruptcyDRP(sub, {
        bankruptcyjsondata: newDRP,
        ccbdrpid: editIndex,
      });
      setEditIndex(null);
    } else {
      setLoading(true);
      await insertBankruptcyDRP(sub, {
        bankruptcyjsondata: newDRP,
      });
      await setData();
    }
    setTempBankruptcyDRP({});
  };

  const isOrgDRP = (tempDRP) => {
    if ("orgName" in tempDRP) {
      return true;
    }
    return false;
  };

  const deleteBankruptcyDisclosureDRP = async ({ dataIndex }) => {
    const newBankruptcyDRPs = bankruptcyDRPs.filter(
      (e) => e.ccbdrpid !== dataIndex
    );
    setBankruptcyDRPs(newBankruptcyDRPs);
    await deleteBankruptcyDRP(sub, dataIndex);
  };

  const editBankruptcyDisclosureDRP = ({ dataIndex }) => {
    setEditIndex(dataIndex);
    const { bankruptcydrp } = bankruptcyDRPs.filter(
      (e) => e.ccbdrpid === dataIndex
    )[0];
    updateTempBankruptcyDRP(bankruptcydrp);
    if (isOrgDRP(bankruptcydrp)) {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_ORG);
    } else updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_1);
  };

  const getPersBankruptcyDRPList = (bankruptcyDRPList) => {
    let list = [];
    if (bankruptcyDRPList !== list && bankruptcyDRPList !== "") {
      for (let i in bankruptcyDRPList) {
        if (!isOrgDRP(bankruptcyDRPList[i].bankruptcydrp)) {
          const {
            bankruptcydrp: {
              actionType,
              actionBankruptcyOption,
              dispositionType,
              actionDate,
              compCreditorName,
            },
            ccbdrpid,
          } = bankruptcyDRPList[i];

          if (actionType === "Bankruptcy") {
            list.push({
              qKey: "14K(1)",
              mainIndex: ccbdrpid,
              description: [`${actionType};`, actionBankruptcyOption],
              status: dispositionType,
              date: actionDate,
            });
          } else if (actionType === "Compromise") {
            list.push({
              qKey: "14K(1)",
              mainIndex: ccbdrpid,
              description: [`${actionType};`, compCreditorName],
              status: dispositionType,
              date: actionDate,
            });
          } else {
            list.push({
              qKey: "14K(1)",
              mainIndex: ccbdrpid,
              description: [actionType],
              status: dispositionType,
              date: actionDate,
            });
          }
        }
      }
    }
    return list;
  };

  const getOrgBankruptcyDRPList = (bankruptcyDRPList) => {
    let list = [];
    if (bankruptcyDRPList !== list && bankruptcyDRPList !== "") {
      for (let i in bankruptcyDRPList) {
        if (isOrgDRP(bankruptcyDRPList[i].bankruptcydrp)) {
          const {
            bankruptcydrp: {
              orgName,
              actionType,
              actionBankruptcyOption,
              compCreditorName,
              dispositionType,
              actionDate,
              drpList,
            },
            ccbdrpid,
          } = bankruptcyDRPList[i];
          if (actionType === "Bankruptcy") {
            if (drpList !== undefined) {
              for (let j in drpList) {
                list.push({
                  qKey: drpList[j],
                  orgName: orgName,
                  mainIndex: ccbdrpid,
                  description: [`${actionType};`, actionBankruptcyOption],
                  status: dispositionType,
                  date: actionDate,
                });
              }
            }
          } else if (actionType === "Compromise") {
            if (drpList !== undefined) {
              for (let j in drpList) {
                list.push({
                  qKey: drpList[j],
                  orgName: orgName,
                  mainIndex: ccbdrpid,
                  description: [`${actionType};`, compCreditorName],
                  status: dispositionType,
                  date: actionDate,
                });
              }
            }
          } else {
            if (drpList !== undefined) {
              for (let j in drpList) {
                list.push({
                  qKey: drpList[j],
                  orgName: orgName,
                  mainIndex: ccbdrpid,
                  description: [actionType],
                  status: dispositionType,
                  date: actionDate,
                });
              }
            }
          }
        }
      }
    }
    return list;
  };

  const updateBondDisclosureDRPs = async (newDRP) => {
    if (editIndex !== null) {
      let newBondDRPs = bondDRPs.filter((e) => e.ccbdrpid !== editIndex);
      newBondDRPs.push({
        bonddrp: newDRP,
        ccbdrpid: editIndex,
      });
      setBondDRPs(newBondDRPs);
      await updateBondDRP(sub, {
        bondjsondata: newDRP,
        ccbdrpid: editIndex,
      });
      setEditIndex(null);
    } else {
      setLoading(true);
      await insertBondDRP(sub, {
        bondjsondata: newDRP,
      });
      await setData();
    }
  };
  const deleteBondDisclosureDRP = async ({ dataIndex }) => {
    const newBondDRPs = bondDRPs.filter((e) => e.ccbdrpid !== dataIndex);
    setBondDRPs(newBondDRPs);
    await deleteBondDRP(sub, dataIndex);
  };
  const editBondDisclosureDRP = ({ dataIndex }) => {
    setEditIndex(dataIndex);
    const { bonddrp } = bondDRPs.filter((e) => e.ccbdrpid === dataIndex)[0];
    updateTempBondDRP(bonddrp);
    updateCCBForm(CCB_FORM_ID.ADD_BOND_DRP_PAGE_1);
  };

  /****************************************************
   * UPDATE/INSERT/DELETE Judgment/Lien DRP Functions *
   ****************************************************/
  const updateJudgmentDisclosureDRPs = async (newDRP) => {
    if (editIndex !== null) {
      let newJudgmentLienDRPs = judgmentLienDRPs.filter(
        (e) => e.ccbdrpid !== editIndex
      );
      newJudgmentLienDRPs.push({
        judgmentliendrp: newDRP,
        ccbdrpid: editIndex,
      });
      setJudgmentLienDRPs(newJudgmentLienDRPs);
      await updateJudgmentLienDRP(sub, {
        judgmentlienjsondata: newDRP,
        ccbdrpid: editIndex,
      });
      setEditIndex(null);
    } else {
      setLoading(true);
      await insertJudgmentLienDRP(sub, {
        judgmentlienjsondata: newDRP,
      });
      await setData();
    }
  };
  const deleteJudgmentLienDisclosureDRP = async ({ dataIndex }) => {
    const newJudgmentLienDRPs = judgmentLienDRPs.filter(
      (e) => e.ccbdrpid !== dataIndex
    );
    setJudgmentLienDRPs(newJudgmentLienDRPs);
    await deleteBondDRP(sub, dataIndex);
  };
  const editJudgmentLienDisclosureDRP = ({ dataIndex }) => {
    setEditIndex(dataIndex);
    const { judgmentliendrp } = judgmentLienDRPs.filter(
      (e) => e.ccbdrpid === dataIndex
    )[0];
    updateTempJudgmentLienDRP(judgmentliendrp);
    updateCCBForm(CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_1);
  };

  const getDRPList = (DRPList, drptype) => {
    let list = [];
    if (drptype === "bond" && DRPList !== list && DRPList !== "") {
      for (let i in DRPList) {
        const {
          bonddrp: { dispositionType, firmName },
          ccbdrpid,
        } = DRPList[i];
        list.push({
          qKey: "14L",
          mainIndex: ccbdrpid,
          description: [dispositionType],
          date: firmName,
        });
      }
    }

    if (drptype === "judgment/lien" && DRPList !== list && DRPList !== "") {
      for (let i in DRPList) {
        const {
          judgmentliendrp: {
            judgmentLienDetails: {
              judgmentLienType,
              judgmentLienHolder,
              dateFiledWithCourt,
            },
          },
          ccbdrpid,
        } = DRPList[i];
        list.push({
          qKey: "14M",
          mainIndex: ccbdrpid,
          description: [judgmentLienType],
          date: dateFiledWithCourt,
          orgName: judgmentLienHolder,
        });
      }
    }
    return list;
  };

  const renderForm = (id) => {
    switch (id) {
      case CCB_FORM_ID.FINANCIAL_DISCL:
        return (
          <FinancialDisclosureForm
            header={headerText.HEADER}
            toggleForm={toggleForm}
            oktaSubId={oktaSubId}
            updateCCBForm={updateCCBForm}
            backFormId={FORM_ID.TERMINATION_DISCLOSURE}
            nextFormId={CCB_FORM_ID.DRP_SUMMARY}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            updateCCB14Step={updateCCB14Step}
            contractType={contractType}
            loadingProp={loading}
            setLoadingProp={setLoading}
            persBankruptcy={persBankruptcy}
            orgBankruptcy={orgBankruptcy}
            bondDiscl={bondDiscl}
            judgLienDisclosures={judgLienDisclosures}
            bondList={bondDRPs}
            judgmentLienList={judgmentLienDRPs}
            bankruptcyList={bankruptcyDRPs}
            setData={setData}
            unchecked={unchecked}
          />
        );
      case CCB_FORM_ID.DRP_SUMMARY:
        return (
          <DisclosureReportingPageLayout
            header={headerText.HEADER}
            backFormId={CCB_FORM_ID.FINANCIAL_DISCL}
            nextFormId={
              contractType === CONTRACT_TYPE_DESCRIPTIONS.MMLIS
                ? FORM_ID.MMLIS_INTRO
                : contractType === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
                ? FORM_ID.REVIEW_SIGN_1
                : FORM_ID.DIRECT_DEPOSIT
            }
            updateCCBForm={updateCCBForm}
            toggleForm={toggleForm}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            ind="finDisclosureCompleteInd"
            isLoading={loading}
            isNextDisabled={isDRPNextDisabled}
          >
            <FinancialDisclosureDRPSummary
              oktaSubId={oktaSubId}
              updateCCBForm={updateCCBForm}
              updateCCB14CompletedInd={updateCCB14CompletedInd}
              persBankruptcy={persBankruptcy}
              orgBankruptcy={orgBankruptcy}
              bondDiscl={bondDiscl}
              judgLienDisclosures={judgLienDisclosures}
              setIsDRPCompleted={setIsDRPCompleted}
              updateCCB14Step={updateCCB14Step}
              setLoading={setLoading}
              bondList={bondDRPs}
              judgmentLienList={judgmentLienDRPs}
              bankruptcyList={bankruptcyDRPs}
              getDRPList={getDRPList}
              getPersBankruptcyDRPList={getPersBankruptcyDRPList}
              getOrgBankruptcyDRPList={getOrgBankruptcyDRPList}
              deleteBondDisclosureDRP={deleteBondDisclosureDRP}
              editBondDisclosureDRP={editBondDisclosureDRP}
              deleteJudgmentLienDisclosureDRP={deleteJudgmentLienDisclosureDRP}
              editJudgmentLienDisclosureDRP={editJudgmentLienDisclosureDRP}
              deleteBankruptcyDisclosureDRP={deleteBankruptcyDisclosureDRP}
              editBankruptcyDisclosureDRP={editBankruptcyDisclosureDRP}
              isDRPNextDisabled={isDRPNextDisabled}
              setIsDRPNextDisabled={setIsDRPNextDisabled}
            />
          </DisclosureReportingPageLayout>
        );
      case CCB_FORM_ID.ADD_BANKRUPTCY_ORG:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={1}
            steps={7}
            details={bankruptcyStepHeaderText.ORG_DETAILS}
            section={subHeaderText.ORG_BANKRUPTCY}
          >
            <BankruptcyDisclosureOrgDetails
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_1}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              updateTempBankruptcyDRP={updateTempBankruptcyDRP}
              tempBankruptcyDRP={tempBankruptcyDRP}
              isOrg={isOrg}
              setIsOrg={setIsOrg}
            ></BankruptcyDisclosureOrgDetails>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_1:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={!isOrg ? 1 : 2}
            steps={!isOrg ? 6 : 7}
            details={bankruptcyStepHeaderText.ACTION_DETAILS}
            section={
              !isOrg
                ? subHeaderText.PERS_BANKRUPTCY_ADD_DRP
                : subHeaderText.ORG_BANKRUPTCY
            }
          >
            <BankruptcyDisclosureActionDetails
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_2}
              backFormId={
                !isOrg
                  ? CCB_FORM_ID.DRP_SUMMARY
                  : CCB_FORM_ID.ADD_BANKRUPTCY_ORG
              }
              updateTempBankruptcyDRP={updateTempBankruptcyDRP}
              tempBankruptcyDRP={tempBankruptcyDRP}
              isOrg={isOrg}
              setIsOrg={setIsOrg}
            ></BankruptcyDisclosureActionDetails>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_2:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={!isOrg ? 2 : 3}
            steps={!isOrg ? 6 : 7}
            details={bankruptcyStepHeaderText.COURT_DETAILS}
            section={
              !isOrg
                ? subHeaderText.PERS_BANKRUPTCY_ADD_DRP
                : subHeaderText.ORG_BANKRUPTCY
            }
          >
            <BankruptcyDisclosureCourtDetails
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_3}
              backFormId={CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_1}
              updateTempBankruptcyDRP={updateTempBankruptcyDRP}
              tempBankruptcyDRP={tempBankruptcyDRP}
            ></BankruptcyDisclosureCourtDetails>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_3:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={!isOrg ? 3 : 4}
            steps={!isOrg ? 6 : 7}
            details={bankruptcyStepHeaderText.DISPOSITION_DETAILS}
            section={
              !isOrg
                ? subHeaderText.PERS_BANKRUPTCY_ADD_DRP
                : subHeaderText.ORG_BANKRUPTCY
            }
          >
            <BankruptcyDisclosureDispositionDetails
              updateCCBForm={updateCCBForm}
              hasCompromise={hasCompromise}
              hasSIPA={hasSIPA}
              backFormId={CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_2}
              updateTempBankruptcyDRP={updateTempBankruptcyDRP}
              tempBankruptcyDRP={tempBankruptcyDRP}
            ></BankruptcyDisclosureDispositionDetails>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_4:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={!isOrg ? 4 : 5}
            steps={!isOrg ? 6 : 7}
            details={bankruptcyStepHeaderText.COMPROMISE_DETAILS}
            section={
              !isOrg
                ? subHeaderText.PERS_BANKRUPTCY_ADD_DRP
                : subHeaderText.ORG_BANKRUPTCY
            }
          >
            <BankruptcyDisclosureCompromiseDetails
              updateCCBForm={updateCCBForm}
              hasSIPA={hasSIPA}
              backFormId={CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_3}
              updateTempBankruptcyDRP={updateTempBankruptcyDRP}
              tempBankruptcyDRP={tempBankruptcyDRP}
            ></BankruptcyDisclosureCompromiseDetails>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_5:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={!isOrg ? 5 : 6}
            steps={!isOrg ? 6 : 7}
            details={bankruptcyStepHeaderText.SIPA_DETAILS}
            section={
              !isOrg
                ? subHeaderText.PERS_BANKRUPTCY_ADD_DRP
                : subHeaderText.ORG_BANKRUPTCY
            }
          >
            <BankruptcyDisclosureSIPADetails
              updateCCBForm={updateCCBForm}
              hasCompromise={hasCompromise}
              hasSIPA={hasSIPA}
              nextFormId={CCB_FORM_ID.ADD_BANKRUPTCY_DRP_COMMENTS}
              updateTempBankruptcyDRP={updateTempBankruptcyDRP}
              tempBankruptcyDRP={tempBankruptcyDRP}
            ></BankruptcyDisclosureSIPADetails>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BANKRUPTCY_DRP_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={!isOrg ? 6 : 7}
            steps={!isOrg ? 6 : 7}
            details={bankruptcyStepHeaderText.COMMENTS}
            section={
              !isOrg
                ? subHeaderText.PERS_BANKRUPTCY_ADD_DRP
                : subHeaderText.ORG_BANKRUPTCY
            }
          >
            <BankruptcyDisclosureComments
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              hasCompromise={hasCompromise}
              hasSIPA={hasSIPA}
              updateTempBankruptcyDRP={updateTempBankruptcyDRP}
              tempBankruptcyDRP={tempBankruptcyDRP}
              updateBankruptcyDisclosureDRPs={updateBankruptcyDisclosureDRPs}
            ></BankruptcyDisclosureComments>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BOND_DRP_PAGE_1:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={1}
            steps={3}
            details={bondStepHeaderText.ORGANIZATION_INFORMATION}
            section={subHeaderText.BOND_DISCL_ADD_DRP}
          >
            <BondAddDRPOrganizationInformationForm
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_BOND_DRP_PAGE_2}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              updateTempDRP={updateTempBondDRP}
              tempBondDRP={tempBondDRP}
            ></BondAddDRPOrganizationInformationForm>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BOND_DRP_PAGE_2:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={2}
            steps={3}
            details={bondStepHeaderText.DISPOSITION_DETAILS}
            section={subHeaderText.BOND_DISCL_ADD_DRP}
          >
            <BondAddDRPDispositionDetails
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_BOND_DRP_COMMENTS}
              backFormId={CCB_FORM_ID.ADD_BOND_DRP_PAGE_1}
              updateTempDRP={updateTempBondDRP}
              tempBondDRP={tempBondDRP}
            ></BondAddDRPDispositionDetails>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_BOND_DRP_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={3}
            steps={3}
            details={bondStepHeaderText.COMMENTS}
            section={subHeaderText.BOND_DISCL_ADD_DRP}
          >
            <BondAddDRPComments
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              backFormId={CCB_FORM_ID.ADD_BOND_DRP_PAGE_2}
              updateTempDRP={updateTempBondDRP}
              tempBondDRP={tempBondDRP}
              updateBondDisclosureDRPs={updateBondDisclosureDRPs}
            ></BondAddDRPComments>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_1:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={1}
            steps={4}
            details={judgmentLienStepHeaderText.JUDGMENT_LIEN_DETAILS}
            section={subHeaderText.JUDG_LIEN_ADD_DRP}
          >
            <JudgmentLienAddDRPJudgmentLienDetailsForm
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_2}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              updateTempDRP={updateTempJudgmentLienDRP}
              tempJudgmentLienDRP={tempJudgmentLienDRP}
              updateBondDisclosureDRPs={updateBondDisclosureDRPs}
            ></JudgmentLienAddDRPJudgmentLienDetailsForm>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_2:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={2}
            steps={4}
            details={judgmentLienStepHeaderText.COURT_DETAILS}
            section={subHeaderText.JUDG_LIEN_ADD_DRP}
          >
            <JudgmentLienAddDRPCourtDetailsForm
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_3}
              backFormId={CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_1}
              updateTempDRP={updateTempJudgmentLienDRP}
              tempJudgmentLienDRP={tempJudgmentLienDRP}
              updateBondDisclosureDRPs={updateBondDisclosureDRPs}
            ></JudgmentLienAddDRPCourtDetailsForm>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_3:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={3}
            steps={4}
            details={judgmentLienStepHeaderText.DISPOSITION_DETAILS}
            section={subHeaderText.JUDG_LIEN_ADD_DRP}
          >
            <JudgmentLienAddDRPDispositionDetailsForm
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_COMMENTS}
              backFormId={CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_2}
              updateTempDRP={updateTempJudgmentLienDRP}
              tempJudgmentLienDRP={tempJudgmentLienDRP}
              updateBondDisclosureDRPs={updateBondDisclosureDRPs}
            ></JudgmentLienAddDRPDispositionDetailsForm>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={4}
            steps={4}
            details={judgmentLienStepHeaderText.COMMENTS}
            section={subHeaderText.JUDG_LIEN_ADD_DRP}
          >
            <JudgmentLienAddDRPCommentsForm
              updateCCBForm={updateCCBForm}
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              backFormId={CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_3}
              updateTempDRP={updateTempJudgmentLienDRP}
              tempJudgmentLienDRP={tempJudgmentLienDRP}
              updateJudgmentDisclosureDRPs={updateJudgmentDisclosureDRPs}
            ></JudgmentLienAddDRPCommentsForm>
          </AddDRPFormLayout>
        );
      default:
        break;
    }
  };

  return (
    <div className="regulatory-action-disclosure-wrapper">
      {renderForm(currentFormId)}
    </div>
  );
};

export default FinancialDisclosure;
